import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// styled-components
const MiniHeaderStyled = styled.div`
  color: #ffffff;
  width: 100%;
  height: 125px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position-x: 50%;
  background-position-y: 50%;
  background-image: ${({ image }) => `url(${image})`};

  @media only screen and (max-width: 425px) {
    height: 90px;
  }
`;

const MiniHeaderEffect = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
`;

const MiniHeaderSpan = styled.span`
  font-family: 'Open Sans Condensed';
  font-size: 4.2857em;
  font-weight: 600;
  font-stretch: 100%;
  text-size-adjust: 100%;
  display: inline;

  @media only screen and (max-width: 425px) {
    font-size: 2em;
  }
`;

const Dcontainer = styled.div`
  max-width: 90%;
  width: 980px;
  margin: 0 auto;
`;

const MiniHeader = React.memo((props) => {
  const { text, background } = props;
  return (
    <MiniHeaderStyled image={background}>
      <MiniHeaderEffect>
        <Dcontainer>
          <MiniHeaderSpan>{text}</MiniHeaderSpan>
        </Dcontainer>
      </MiniHeaderEffect>
    </MiniHeaderStyled>
  );
});

MiniHeader.defaultProps = {
  text: ``,
  background: ``,
};

MiniHeader.propTypes = {
  text: PropTypes.string,
  background: PropTypes.string,
};

export default MiniHeader;
