import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, message } from 'antd';

// components
import Layout from '../components/layout';
import Seo from '../components/seo';
import MiniHeader from '../components/mini-header';
import Captcha from '../components/captcha';

// img
import headerBackground from '../images/showroom/header-showroom.png';

// style-components
const ShowRoomContianer = styled.div`
  padding: 3rem 0;
`;

const ShowroomIframeWrapper = styled.div`
  max-width: 853px;
  height: 480px;
  margin: 0 auto;
  margin-bottom: 3rem;
`;

const DContainer = styled.div`
  max-width: 90%;
  width: 980px;
  margin: 0 auto;

  @media only screen and (max-width: 425px) {
    max-width: 95%;
  }
`;

const ShowroomIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const ShowroomContactP = styled.p`
  margin-bottom: 2em;
`;

const ShowroomContactSpan = styled.span`
  font-size: 1.1em;
`;

const ContactForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0 1em;

  @media only screen and (max-width: 425px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;
const ContactButton = styled(Button)`
  border: none;
  width: 60%;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  padding: 0em 1em;
  background-color: rgb(255, 215, 0);

  @media only screen and (max-width: 525px) {
    width: 100%;
  }

  &:hover,
  &:focus {
    color: rgb(255, 215, 0);
    background: #000;
    border-color: 1px solid rgb(255, 215, 0);
  }
`;

const ShowroomFormComments = styled(Form.Item)`
  grid-column: span 2;

  @media only screen and (max-width: 425px) {
    grid-column: auto;
  }
`;

const Showroom = React.memo(() => {
  const [form] = Form.useForm();
  const [statusMessage, setStatus] = useState(false);
  const [reCaptchaStatus, setReCaptchaStatus] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);

  const onReset = () => {
    form.resetFields();
    setShouldReset(true);
    setStatus(false);
  };

  const onFinish = (values) => {
    if (reCaptchaStatus) {
      setStatus(true);
      setShouldReset(false);
      fetch('http://localhost:4000/send-email', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.msg === 'success') {
            message.success('Tu mensaje fue enviado, pronto estaremos en contacto contigo.', 5);
          }
          if (data?.msg === 'fail') {
            message.error(
              'Oops parece que tenemos un problema, por favor comunicate con nosotros por medio de los números de contacto',
              5
            );
          }
        })
        .catch(() => {
          message.error(
            'Oops parece que tenemos un problema, por favor comunicate con nosotros por medio de los números de contacto',
            5
          );
        })
        .finally(() => {
          onReset();
        });
    } else {
      message.error('Por favor llena el reCAPTCHA');
    }
  };

  return (
    <Layout>
      <Seo title="Showroom" />
      <MiniHeader text="Nuestro Showroom" background={headerBackground} />
      <ShowRoomContianer>
        <DContainer>
          <ShowroomIframeWrapper>
            <ShowroomIframe
              title="Showroom-Sto Colombia"
              src="https://my.matterport.com/show/?m=HbYJy6QZXR3"
              frameBorder="0"
              allowFullScreen
              allow="vr"
            ></ShowroomIframe>
          </ShowroomIframeWrapper>
          <div>
            <ShowroomContactP>
              <ShowroomContactSpan>
                Estas interesado en saber más de nuestros productos, sientete libre de contactarnos.
              </ShowroomContactSpan>
            </ShowroomContactP>
            <ContactForm form={form} method="POST" onFinish={onFinish} scrollToFirstError>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, completa este campo, es requerido!',
                  },
                ]}
              >
                <Input addonBefore="Nombre" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Este campo no es valido!',
                  },
                  {
                    required: true,
                    message: 'Por favor, completa este campo, es requerido!',
                  },
                ]}
              >
                <Input addonBefore="Email" />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, completa este campo, es requerido!',
                  },
                ]}
              >
                <Input addonBefore="Celular" />
              </Form.Item>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, completa este campo, es requerido!',
                  },
                ]}
              >
                <Input addonBefore="Ciudad" />
              </Form.Item>
              <ShowroomFormComments name="comments">
                <Input.TextArea placeholder="Comentarios.." style={{ height: '32px' }} />
              </ShowroomFormComments>
              <Captcha setStatus={setReCaptchaStatus} shouldReset={shouldReset} />
              <ContactButton
                type="primary"
                htmlType="submit"
                disabled={statusMessage}
                loading={statusMessage}
              >
                Enviar
              </ContactButton>
            </ContactForm>
          </div>
        </DContainer>
      </ShowRoomContianer>
    </Layout>
  );
});

export default Showroom;
